import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';

const MockBackButton = () => {
	const go = useNavigate();
	let location = useLocation();
	let endIdx = location.pathname.lastIndexOf('/');
	let goBackPath = location.pathname.slice(0, endIdx);

	const handleBackButton = () => {
		const cm = window.confirm('Are you sure you want to leave mock interview?');
		if (cm) {
			localStorage.clear();
			go(goBackPath);
		}
		return;
	};
	return (
		<button
			className='button'
			onClick={handleBackButton}
			style={{ position: 'absolute', left: 0, top: 0, color: 'var(--darkBlue)' }}
		>
			<FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: '7px', color: 'var(--darkBlue)' }} /> Leave
		</button>
	);
};

export default MockBackButton;

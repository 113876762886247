import React, { useEffect, useState } from 'react';
import MockDoneBlock from './Partials/MockDoneBlock';
import MockTimerBanner from './Partials/MockTimerBanner';
import styles from './MockInnerPage.module.css';
import ContentGenerator from '../../../components/Partials/ContentGenerator';
import MockBackButton from './Partials/MockBackButton';
import { useLocation } from 'react-router-dom';
import { CodeEditor } from '../../../components/CodeEditor';
/**
 * TODO:
 * Refine "DONE" logic
 * Refine limit logic
 * Clear storage on component destruction useEffect
 */
const getPersistedData = () => {
	const persistentCurrentQuestion = JSON.parse(localStorage.getItem('currentQuestion'));
	const persistentPrevQuestion = JSON.parse(localStorage.getItem('prevQuestion'));
	const persistentQIndex = parseInt(localStorage.getItem('qIndex'));
	const persistentWhiteboardLimit = parseInt(localStorage.getItem('whiteboardLimit'));
	return {
		persistentCurrentQuestion,
		persistentPrevQuestion,
		persistentQIndex,
		persistentWhiteboardLimit,
	};
};

export const MockInnerPage = ({ ALL_QUESTIONS, mockTitle, totalMockTime }) => {
	// Whiteboard start
	let location = useLocation();
	const mockUrl = location.pathname.slice(location.pathname.length - 1);

	const getRandomQuestion = (whereFrom, qIdx) => {
		let questionContent = 'There is no question for such topic!';
		ALL_QUESTIONS?.forEach((qObj) => {
			if (qObj[whereFrom]) {
				questionContent = qObj[whereFrom][qIdx];
			}
		});
		return questionContent;
	};

	// Enrich all questions with title and content
	const QUESTIONS_DATABASE = {
		questions: ALL_QUESTIONS?.map((q, i) => {
			const whereFromKey = Object.keys(q)[0];
			return {
				// qTitle: `Question ${i + 1}`,
				qTitle: `${i + 1}`,
				content: (qIdx) => getRandomQuestion(whereFromKey, qIdx),
			};
		}),
	};

	const { persistentCurrentQuestion, persistentPrevQuestion, persistentQIndex, persistentWhiteboardLimit } =
		getPersistedData();

	// Defaults
	const defaultCurrentQuestion = {
		qTitle: QUESTIONS_DATABASE.questions[0]?.qTitle,
		content: QUESTIONS_DATABASE.questions[0]?.content(0),
	};
	// States
	const [qIndex, setQIndex] = useState(persistentQIndex ? persistentQIndex : 0);
	const [currentQuestion, setCurrentQuestion] = useState(
		persistentCurrentQuestion ? persistentCurrentQuestion : defaultCurrentQuestion
	);
	const [prevQuestion, setPrevQuestion] = useState(persistentPrevQuestion ? persistentPrevQuestion : null);
	const [nextQuestion, setNextQuestion] = useState(null);

	const [trackDisabled, setTrackDisabled] = useState(true);
	const [showDone, setShowDone] = useState(false);
	const [isPrevBtnClicked, setIsPrevBtnClicked] = useState(false);
	const [resetCode, setResetCode] = useState(false);

	let limit = 0;
	if (mockUrl === '1') limit = 6;
	else if (mockUrl === '2') limit = 14;
	else if (mockUrl === '3') limit = 17;
	else if (mockUrl === '4') limit = 23;
	else if (mockUrl === '5') limit = 10;
	else limit = 6;

	const [whiteboardLimit, setWhiteBoardLimit] = useState(persistentWhiteboardLimit ? persistentWhiteboardLimit : 0);

	const randomIndexGenerator = (idx) => {
		const targetObj = ALL_QUESTIONS[idx];
		const targetKey = Object.keys(targetObj)[0];
		return Math.floor(Math.random() * ALL_QUESTIONS[idx][targetKey].length);
	};

	// Initial render and checks
	useEffect(() => {
		// Only store when prev button not clicked
		if (!isPrevBtnClicked) {
			localStorage.setItem('qIndex', qIndex);
		}
		if (!persistentCurrentQuestion) {
			localStorage.setItem('currentQuestion', JSON.stringify(currentQuestion));
		}
		// Enable prev button on refresh
		if (prevQuestion) {
			setTrackDisabled(false);
		}
	}, [qIndex, persistentCurrentQuestion, currentQuestion, isPrevBtnClicked, prevQuestion]);

	const getLastQuestion = () => {
		let prevWhiteIndex = whiteboardLimit - 1;
		setWhiteBoardLimit(prevWhiteIndex);
		localStorage.setItem('whiteboardLimit', prevWhiteIndex);

		setIsPrevBtnClicked(true);
		setResetCode((prevState) => !prevState);
		// update qIndex in storage
		let prevQIndex = qIndex - 1;
		localStorage.setItem('qIndex', prevQIndex);
		// setQIndex(prevQIndex);

		// Store current question to next in state and storage
		setNextQuestion(currentQuestion);
		localStorage.setItem('nextQuestion', JSON.stringify(currentQuestion));

		// Update current question with prev
		setCurrentQuestion(prevQuestion);
		localStorage.setItem('currentQuestion', JSON.stringify(prevQuestion));

		// Reset prev question
		setPrevQuestion(null);
		localStorage.removeItem('prevQuestion');

		// Disable prev
		setTrackDisabled(true);
	};

	const getNextQuestion = () => {
		let nextWhiteIndex = whiteboardLimit + 1;
		setWhiteBoardLimit(nextWhiteIndex);
		localStorage.setItem('whiteboardLimit', nextWhiteIndex);

		setIsPrevBtnClicked(false);
		setResetCode((prevState) => !prevState);

		if (qIndex === QUESTIONS_DATABASE.questions.length - 1 && !isPrevBtnClicked) {
			setShowDone(true);
			localStorage.clear();
			return;
		}

		// Store prev question
		setPrevQuestion(currentQuestion);
		localStorage.setItem('prevQuestion', JSON.stringify(currentQuestion));

		// If next question exists
		if (nextQuestion) {
			setCurrentQuestion(nextQuestion);
			localStorage.setItem('currentQuestion', JSON.stringify(nextQuestion));

			setNextQuestion(null);
			localStorage.removeItem('nextQuestion');

			// Enable prev
			setTrackDisabled(false);
			return;
		}

		// Important
		const nextIndex = qIndex + 1;

		setQIndex(nextIndex);

		// Random question index
		const newRandom = randomIndexGenerator(nextIndex);

		// Update current question in state and storage
		setCurrentQuestion({
			qTitle: QUESTIONS_DATABASE.questions[nextIndex]?.qTitle,
			content: QUESTIONS_DATABASE.questions[nextIndex].content(newRandom),
		});
		localStorage.setItem(
			'currentQuestion',
			JSON.stringify({
				qTitle: QUESTIONS_DATABASE.questions[nextIndex]?.qTitle,
				content: QUESTIONS_DATABASE.questions[nextIndex].content(newRandom),
			})
		);

		// Enable prev
		setTrackDisabled(false);
	};

	if (showDone) {
		// When time finished, clear localstorage and update ui
		localStorage.clear();
		return <MockDoneBlock />;
	}

	return (
		<div>
			<div style={{ position: 'relative' }}>
				<MockBackButton />
				<h1 className={styles.mockHeading}>{mockTitle}</h1>
			</div>
			<div>
				<div className={styles.mockContentBody}>
					<section className={styles.mockHeader}>
						<div>
							<p>
								<strong>Question {currentQuestion?.qTitle}/</strong>
								{ALL_QUESTIONS?.length}
							</p>
						</div>
						<div className={styles.iTimer}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 512 512'
								fill='var(--mainBlue)'
								width={16}
								height={16}
							>
								<path d='M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z' />
							</svg>
							<MockTimerBanner setShowDone={setShowDone} totalMockTime={totalMockTime} />
						</div>
					</section>
					<section className={styles.mockQuestion}>
						{/* <h2>{currentQuestion?.qTitle}</h2> */}
						<ContentGenerator title={'mock'} taskDetails={currentQuestion?.content} />
					</section>
					{whiteboardLimit > limit && (
						<section>
							<CodeEditor initialCode={'// Write your solution below'} resetCode={resetCode} />
						</section>
					)}
					<section className={styles.btns}>
						<button onClick={getLastQuestion} disabled={trackDisabled}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 320 512'
								width={12}
								height={14}
								fill='var(--mainBlue)'
							>
								<path d='M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z' />
							</svg>
							<span>Prev</span>
						</button>
						<button onClick={getNextQuestion}>
							{qIndex >= QUESTIONS_DATABASE.questions.length - 1 && !isPrevBtnClicked ? (
								'DONE'
							) : (
								<>
									<span>Next</span>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 320 512'
										width={12}
										height={14}
										fill='#fff'
									>
										<path d='M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z' />
									</svg>
								</>
							)}
						</button>
					</section>
				</div>
			</div>
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageTitle from '../Partials/PageTitle';
import BackButton from '../Partials/BackButton';
import styles from './SubPageLayout.module.css';
import { faCode, faDisplay, faLaptopCode, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getCurrentPath = (path) => {
	switch (path) {
		case '/frontend':
			return 'Frontend Testing';
		case '/java-exercises':
			return 'Java Exercises';
		case '/js-exercises':
			return 'JavaScript Exercises';
		case '/login':
			return 'Login';
		default:
			return undefined;
	}
};

const SubPageLayout = ({ children }) => {
	const location = useLocation();
	const [currentPath, setCurrentPath] = useState(location?.pathname);
	const isFullWithPage =
		currentPath === '/' || currentPath === '/backend' || currentPath === '/mock-interviews/javascript';
	// This causing to disappear the title and 'Leave' button on a page if there is a popup modal.
	let pageName = location?.state?.name;

	const [mQuery, setMQuery] = React.useState({
		matches: window.innerWidth <= 1024 ? true : false,
	});

	useEffect(() => {
		// Media query listener
		let mediaQuery = window.matchMedia('(min-width: 1024px)');
		mediaQuery.addListener(setMQuery);
		// Set the current path
		setCurrentPath(location.pathname);
		// Cleanup function to remove the media query listener
		return () => mediaQuery.removeListener(setMQuery);
	}, [location.pathname]);

	const mobileInnerWidth = mQuery.matches ? '85%' : '80%';

	const dynamicStyle = isFullWithPage
		? { width: '100%', padding: '0' }
		: { width: mobileInnerWidth, padding: '20px 0' };

	const pagesWithBanner = ['/frontend', '/java-exercises', '/js-exercises'];
	const currentMenuTitles = getCurrentPath(currentPath)?.split(' ');

	const backgroundMap = {
		'/frontend': styles.blueBg,
		'/java-exercises': styles.blueBg,
		'/js-exercises': styles.blueBg,
		'/login': styles.blueBg,
		// add more colors...
	};
	// Catch all - image background
	const patternMap = [{ pattern: /^\/mock-interviews\/javascript\/mock-interview-\d+$/, bg: styles.imageBg }];

	const getBackground = (path) => {
		// Regular
		if (backgroundMap[path]) {
			return backgroundMap[path];
		}
		// Pattern match
		for (const { pattern, bg } of patternMap) {
			if (pattern.test(path)) {
				return bg;
			}
		}
		// Default
		return '';
	};

	return (
		<div className={`${styles.subpageContentWrapper} ${getBackground(currentPath)}`}>
			{pagesWithBanner?.includes(currentPath) && (
				<div className={styles.pageHeaderContainer}>
					<div className={styles.pageHeader}>
						<h1 style={{ fontSize: '45px' }}>{currentMenuTitles[0]}</h1>
						<h1>{currentMenuTitles[1]}</h1>
					</div>
					<div className={`${styles.pageHeaderIcons} is-hidden-touch`}>
						<FontAwesomeIcon icon={faLaptopCode} size='4x' color='var(--darkBlue)' />
						<FontAwesomeIcon icon={faDisplay} size='4x' color='var(--lightBlue)' />
						<FontAwesomeIcon icon={faCode} size='6x' color='var(--darkBlue)' />
						<FontAwesomeIcon icon={faPenToSquare} size='4x' color='var(--lightBlue)' />
					</div>
				</div>
			)}
			<div className={styles.subpageContent} style={dynamicStyle}>
				<div className={styles.wrapper}>
					{pageName && pageName.length && (
						<div className={styles.head}>
							<PageTitle title={pageName} />
							<BackButton />
						</div>
					)}
					<div className={styles.content}>{children}</div>
				</div>
			</div>
		</div>
	);
};
export default SubPageLayout;

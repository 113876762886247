import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';

const BackButton = () => {
	const go = useNavigate();
	let location = useLocation();

	// replace go(-1)
	let endIdx = location.pathname.lastIndexOf('/');
	let goBackPath = location.pathname.slice(0, endIdx);

	return (
		<button className='button' onClick={() => go(goBackPath)}>
			<FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: '7px' }} /> Previous
		</button>
	);
};

export default BackButton;

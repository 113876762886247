import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const MockDoneBlock = () => {
	useEffect(() => {
		localStorage.clear();
	}, []);
	const location = useLocation();
	const isJavaScriptMock = location.pathname.includes('javascript');
	return (
		<div
			style={{
				backgroundColor: 'var(--mainBlue)',
				height: '90%',
				width: '90%',
				color: 'white',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				margin: '0 auto',
			}}
		>
			<div style={{ textAlign: 'center', maxWidth: '75%' }}>
				<h1>Thank you for participating in our mock interview!</h1>
				<p className='mb-5 mt-4'>
					We hope that the experience was valuable and informative for you. Remember that mock interviews are
					a great way to practice and improve your interview skills. We encourage you to continue seeking out
					additional resources if needed.
				</p>
				<Link
					to={isJavaScriptMock ? '/mock-interviews/javascript' : '/mock-interviews/java'}
					style={{ color: 'rgba(244, 168, 55, 1)', fontWeight: 500, lineHeight: '30px', fontSize: '18px' }}
					onClick={() => localStorage.clear()}
				>
					Go to {isJavaScriptMock ? 'JavaScript' : 'Java'} Mock Interviews Home Page
				</Link>
			</div>
		</div>
	);
};

export default MockDoneBlock;

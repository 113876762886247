import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';

const Explanation = () => {
	// TODO: erorr handling?
	const [content, setContent] = useState('');
	const [loading, setLoading] = useState(false);

	const location = useLocation();
	let IndexToCutOff = location.pathname.lastIndexOf('/') + 1;
	const fileName = location.pathname.slice(IndexToCutOff);

	let basePath;
	if (location.pathname.includes('java-exercises')) {
		basePath = 'java-exercises';
	} else if (location.pathname.includes('js-exercises')) {
		basePath = 'javascript-exercises';
	} else {
		console.log('Error: pathname does not include java-exercises or javascript-exercises');
		throw new Error('Unknown path');
	}

	useEffect(() => {
		setLoading(true);
		const blogUrl = require(`../../app/${basePath}/pages/content/${fileName}.md`);
		console.log('blogUrl', blogUrl);
		fetch(blogUrl)
			.then((response) => response.text())
			.then((text) => {
				console.log('text', text);
				setLoading(false);
				setContent(text);
			});
	}, [fileName, basePath]);

	if (loading) {
		// TODO: Not tested
		<ScaleLoader color='#225ad7' />;
	}

	console.log('content', content);
	if (!content) {
		return (
			<div>
				<p>Error happened while loading the content.</p>
				<p> Refresh your page or try again later</p>
			</div>
		);
	}
	return (
		<div className='explanation content'>
			<ReactMarkdown
				children={content}
				remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
				rehypePlugins={[rehypeRaw]}
				components={{
					code({ node, inline, className, children, ...props }) {
						const match = /language-(\w+)/.exec(className || '');
						return !inline && match ? (
							<SyntaxHighlighter
								children={String(children).replace(/\n$/, '')}
								style={coy}
								language={match[1]}
								PreTag='div'
								{...props}
							/>
						) : (
							<code className={className} {...props}>
								{children}
							</code>
						);
					},
				}}
			/>
		</div>
	);
};

export default Explanation;

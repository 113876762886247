import React, { useEffect } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import axios from 'axios';
import styles from './CodeEditor.module.css';
import { customTheme } from '../app/mock-interviews/innerPages/theme';
import { BASE_URL } from '../app/backend-testing/utils/env';
import { indexOf } from 'lodash';

const LANGUAGES = ['nodejs', 'java', 'python3'];
const extensions = [javascript({ jsx: true })];

export const CodeEditor = ({ initialCode, resetCode }) => {
	const [userCode, setUserCode] = React.useState(initialCode);
	const [result, setResult] = React.useState('');
	const [error, setError] = React.useState('');
	const [lang, setLang] = React.useState(LANGUAGES[0]);

	const onChange = React.useCallback((code, viewUpdate) => {
		setUserCode(code);
	}, []);

	useEffect(() => {
		setUserCode(initialCode);
	}, [resetCode]);

	const submit = async (userCode, lanugage) => {
		try {
			const execution_data = {
				script: userCode,
				language: lanugage,
			};

			const options = {
				method: 'POST',
				url: `${BASE_URL}/editor`,
				data: execution_data,
			};

			const response = await axios.request(options);
			const data = response?.data?.data;
			return data;
		} catch (error) {
			console.error('error', error);
			return error;
		}
	};

	const submitCode = async () => {
		try {
			if (userCode === '' || userCode === '// Write your solution below') {
				setResult('No solution found. Write your code');
				return;
			}
			const data = await submit(userCode, LANGUAGES[0]);
			// let { output } = data || '';

			// // remove jdoodle text
			// if (output?.startsWith('/home') || output?.includes('jdoodle')) {
			// 	const index = output.indexOf('js:2');
			// 	setResult(output.slice(index + 5));
			// 	return;
			// }

			const { status, stdout, error, stderr } = data;

			if (stdout === '' && error === '' && stderr === '') {
				setResult('Nothing returned. Maybe you forgot to console log something?');
				return;
			}

			const formattedError = stderr?.slice(stderr?.indexOf('main'));

			if (formattedError) {
				throw new Error(formattedError);
			} else if (error) {
				throw new Error(error);
			}

			setResult(stdout);
			setError('');
		} catch (error) {
			console.error('error', error);

			setError(error.message);
			setResult('');
		}
	};

	return (
		<div className={styles.editorContainer}>
			<div className={styles.controls}>
				<button onClick={submitCode}>Run</button>
			</div>
			<div className={styles.editorBox}>
				<div className={styles.logoBox}>
					<div className={lang === 'nodejs' ? styles.active : null} onClick={() => setLang('nodejs')}>
						<img src='/images/javascript.png' alt='javascript' />
					</div>
					{/* Disabling other languages */}
					<div className={lang === 'java' ? styles.active : null} onClick={() => setLang('nodejs')}>
						<img src='/images/java.png' alt='java' />
					</div>
					<div className={lang === 'python3' ? styles.active : null} onClick={() => setLang('nodejs')}>
						<img src='/images/python.png' alt='python' />
					</div>
				</div>
				<CodeMirror
					theme={customTheme}
					value={userCode}
					minHeight='200px'
					extensions={extensions}
					onChange={onChange}
					className={styles.codeMirror}
				/>
			</div>
			<div className={styles.output}>
				<h4>Output</h4>
			</div>
			<div className={styles.output}>
				<div>
					{error && <pre>{error}</pre>}
					<pre>{result}</pre>
				</div>
			</div>
		</div>
	);
};
